import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    footer: {
      
      padding: theme.spacing(6),
    },
  }));

const Footer = () => {

    const classes = useStyles();
  
    return (
        <footer className={classes.footer}>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" target="_blank" href="https://dibs.co/">
                    dibs.co
                </Link>{' '}
                {new Date().getFullYear()}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                <Link color="inherit" href="mailto:support@dibs.co">
                    support@dibs.co
                </Link>
            </Typography>
            
        </footer>
    );
};
export default Footer;