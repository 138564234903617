import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    wsImgContainer:{
      textAlign: "center",
      display: "block"
    },
    storeImg: {
      width:"300px"
    },
    wsImg: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      //width: "100%",
      maxWidth: "100%",
      //width: "100%",
      [theme.breakpoints.down('sm')]: {
        marginLeft: "-45%",
        maxWidth: "200%",
      },
    },
    wsBg: {
      backgroundImage: `url(${"img/WS-background.png"})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center top",
      minHeight: "825px",
      width: "100%",
      paddingBottom:"50px"
    },
    wsBg2:{
      backgroundColor: "#84c744",
      paddingTop:"50px"
    },
    wsBox2: {
      display:"block",
      backgroundImage: `url(${"img/WS-foreground-gameview.png"})`,
      backgroundSize: "auto 100%",
      backgroundRepeat: "no-repeat",
      
      minHeight: "500px",
      width: "100%",
      [theme.breakpoints.down('sm')]: {
        paddingTop: "300px",
        backgroundSize: "100%",
      }
      
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    wsText: {
      fontSize: "1.75rem",
      color: "#631480",
      textShadow: "2px 2px #fff",
      fontWeight: "bold",
      fontFamily: "Lilita One,Avant Grande,Arial,Helvetica,sans-serif",
      [theme.breakpoints.down('sm')]: {
        fontSize: "1.25rem",
      }
    },
    purpleNote: {
      backgroundImage: `url(${"img/WS-background-wooden.png"})`,
      backgroundSize: "auto 100%",
      backgroundRepeat: "no-repeat",
      color: "#a5a5a5",
      height: "108px",
      width: "320px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      margin: "24px 0",
      padding: "23px 23px 23px 12px",
      color: "#fff",
      fontSize: "1.25rem",
      fontFamily: "Lilita One,Avant Grande,Arial,Helvetica,sans-serif",
      fontWeight: "bold",
      lineHeight: "1.375",
      [theme.breakpoints.down('sm')]: {
        backgroundSize: "100% 100%",
        height: "70px",
        width:"100%",
        padding: "10px",
        textAlign:"center",
        margin: "auto",
        width:"300px",
        fontSize: "1rem",
      }
    },
  }));

  
const Home = () => {

    const classes = useStyles();
  
    return (
      <React.Fragment>
        
          <img src="img/WS-background.png" className={classes.wsImg} />
        
        <div className={classes.wsBg2}>
          
          <Typography className={classes.wsText} component="h2" variant="h2" align="center" gutterBottom>
            Play the game &amp; restore the balance of nature!
          </Typography>
          <Container maxWidth="md" className={classes.wsBox2}>
            <Grid container spacing={1}>
              <Grid container xs spacing={3} justify="center" direction="column" alignItems="flex-end">
                <div className={classes.purpleNote}>Restore nature and rescue endangered species</div>
                <div className={classes.purpleNote}>Locate resources in your neighbourhood</div>
                <div className={classes.purpleNote}>Give back by planting trees in the real world</div>
              </Grid>
            </Grid>
          </Container>
          <Grid container xs spacing={3} justify="center">
            {/* <Grid item>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=co.dibs.wildlifesanctuary">
                <img className={classes.storeImg} src="/img/play-store.png" />
              </a>
            </Grid> */}
            <Grid item>
              <a target="_blank" href="https://apps.apple.com/app/wildlife-sanctuary/id1569616628">
                <img className={classes.storeImg} src="/img/app-store.png" />
              </a>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
};
export default Home;