import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Footer from './components/footer';
import Terms from './components/terms';
import Privacy from './components/privacy';
import Home from './components/home';


const themeLight = createMuiTheme({
  palette: {
    background: {
      default: "#38c2ad"
    }

    },
});


const App = () => {
  
  return (
    <MuiThemeProvider theme={themeLight}>
      <CssBaseline />
      <main>
        <Router>
          <Switch>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </main>
      <Footer />
    </MuiThemeProvider>
  );
}

export default App;
