import React from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding:"0px"
    },
    container: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "56.25%" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      },
    responsiveIframe: {
        position: "absolute",
        top: "0px",
        left: "0px",
        bottom: "0px",
        right: "0px",
        width: "100%",
        height: "100%"
    }
}));

const Privacy = () => {
    
    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.root}>
            <Typography component="div" className={classes.container} style={{ backgroundColor: '#cfe8fc', height: '100vh' }}>

                <iframe className={classes.responsiveIframe} src="https://docs.google.com/document/d/e/2PACX-1vRdwTRvGMmeyMGJvqycdM4MmtbfcuQUu3oneU66MnJVAWEJDf96lyStc-833P0WSgJdwjfiWok7F6T8/pub?embedded=true"></iframe>
                
            </Typography>
        </Container>
    );
};
export default Privacy;